import React from "react";
import styled from "styled-components";

import {useRef} from "react";
import ApplePayImgImg from "../../assets/applePay.png";
import {auto} from "@popperjs/core";

const media={
    mobile: (...args) => styled`
    @media (max-width: 768px) {
      ${styled(...args)}
    }
  `,
};

const Container=styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;

  //background-color: #01413b;
  overflow: hidden;
`;
const Section=styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
  
  display: flex;
  flex-direction: row; // Default to row

  ${media.mobile`
    flex-direction: column; // Stack vertically on small screens
  `}
`;
const Phone=styled.div`
  width: 100%;
  height: 70%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  cursor: grab;
`;

const Details=styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; // Default padding
  text-align: center; // Center text for a better mobile view

  ${media.mobile`
    padding: 10px; // Reduce padding on small screens
  `}
`;

const Title=styled.h2`
  font-size: var(--fontxl);
  padding: 0.3rem;
`;

const SubTitle=styled.h2`
  font-size: var(--fontmd);
  font-family: var(--fontR);
`;

const ButtonContainer=styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
`;

const Btn=styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0.4rem 1rem;
  border-radius: 50px;

  border: none;
  outline: none;

  background-color: var(--blue);
  color: var(--white);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
const BtnLink=styled.a`
  color: var(--blue);
  text-decoration: none;
  margin-left: 1.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const IndicatorText=styled.div`
  font-size: var(--fontsm);
  position: absolute;
  top: 1rem;
`;

const PhoneX=() => {
    const sectionRef=useRef(null);

    return (
        // <Container className="bg-gradient-to-r from-red-700 to-emerald-500 30% to-black-500 90%">
        <Container className="bg-gradient-to-r from-red-700 to-emerald-600 30% to-black-500 30%">
            <Section ref={sectionRef} className="grid grid-cols-2">
                <Details className="px-10 relative">
                    <p className="text-6xl mb-20 text-white">Delt<sup>®</sup></p>
                    <p className="justify-center items-center mb-10  text-3xl text-white">Discover the future of finance now. Earn up to 200gh in rewards with Delt<sup>®</sup></p>
                    <p className="justify-center items-center text-3xl text-white"></p>
                    <SubTitle className="text-white">On average, Delt<sup>®</sup> users can secure up to $200 in rewards by leveraging our offerings.</SubTitle>
                    <ButtonContainer className="mt-3">
                        <button className="bg-black px-6 py-2 rounded-full">Start Earning | Venturist</button>
                        <button href="#" className="mx-10 hover:underline">Learn More &#x2192;</button>
                    </ButtonContainer>
                </Details>

                <Phone className="mt-10 relative">
                    <div className="relative flex-center">
                        <div className="overflow-hidden flex-center">
                            <img
                                src={ApplePayImgImg}
                                alt="frame"
                                className="bg-transparent h-auto"

                                style={{width: "70%",maxHeight: "80%",height: auto,paddingTop: 150}}
                            />
                        </div>

                    </div>
                </Phone>
            </Section>
        </Container>
    );
};

export default PhoneX;
