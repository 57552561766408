import React from 'react';
// Import your image or reference it directly in the src attribute below
import ladyWithLaptopImg from '../../assets/0_2 (4).webp';

const Sections = () => {
    return (
        <div className="flex flex-wrap md:flex-nowrap">
            <div className="w-full md:w-1/2 text-white px-20 py-4 flex flex-col justify-center">
                <h2 className="text-4xl font-bold mb-4 text-red-400 pt-4">Business with confidence</h2>
                <p className="mt-2 mb-10">
                    Step into the future of business with confidence, Elai ensures your path to success is both innovative and efficient. Our comprehensive suite provides you with all the tools and resources needed to elevate your journey
                </p>
                <button className="bg-orange-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 hover:underline">
                    Learn more
                </button>
            </div>
            <div className="w-full md:w-1/2">
                <img
                    src={ladyWithLaptopImg}
                    alt="A group of young women with a race car"
                    className="h-100 object-cover"
                />
            </div>
        </div>
    );
};

export default Sections;
