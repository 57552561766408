import './App.css';
import Hero from './Components/Hero/Hero';
import Plans from './Components/Plans/Plans';
import Programs from './Components/Programs/Programs';
import Reasons from './Components/Reasons/Reasons';
import Testimonials from './Components/Testimonials/Testimonials';
import Footer from './Components/Footer/Footer';
import Join from './Components/Join/Join';
// import Contact from './Components/Contact/Contact';
import Sections from './Components/Sections/Sections';
import ConnectWithUs from './Components/ConnectWithUs/ConnectWithUs';
import TogetherWeGrow from './Components/TogetherWeGrow/TogetherWeGrow';
import PhoneX from './Components/PhoneX/PhoneX';
import SliderDesign from "./Components/PartnerSlider/SliderDesign";


function App() {
  return (
    <div className="App">
      <Hero />
      <SliderDesign />
      <Programs />
      <Reasons/>
      <Sections />
      <TogetherWeGrow /> 
      <Plans/>
      <Testimonials/>
      <Join />
      <PhoneX />
      {/* <Contact /> */}
      <ConnectWithUs />
      <Footer/>
    </div>
  );
}

export default App;
