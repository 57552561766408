import React from 'react';
// Import your image or reference it directly in the src attribute below
import connectWithUsImage from '../../assets/connectWithUs.webp';

const TogetherWeGrow=() => {
    return (
        <div className="flex flex-wrap md:flex-nowrap h-1/2">
            <div className="w-full md:w-1/2">
                <img
                    src={connectWithUsImage}
                    alt="A group of young women with a race car"
                    className="h-100 object-cover"
                />
            </div>
            <div className="w-full md:w-1/2  text-white px-20 py-2 flex flex-col justify-center">
                <h2 className="text-4xl font-bold mb-4">Together we build and grow!</h2>
                <p className="mt-5 mb-10">
                    Join a community of forward-thinkers who have already embarked on this journey to redefine the landscape of their industries. With our platform, barriers are transformed into bridges, challenges into opportunities, and visions into realities.

                </p>
                <button
                    className="bg-orange-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300">
                    Learn more
                </button>
            </div>

        </div>
    );
};

export default TogetherWeGrow;
