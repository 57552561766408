import React from 'react';
// Import your image or reference it directly in the src attribute below
import connectWithUsImage from '../../assets/office.webp';

const ConnectWithUs=() => {
    return (
        <div className="flex flex-wrap md:flex-nowrap h-1/2">
            <div className="w-full md:w-1/2 text-white px-20 py-2 flex flex-col justify-center border-gray-900 border-opacity-95 border-r-2">
                <h2 className="text-4xl font-bold mb-4">Get In Touch With Elai</h2>
                <p className="mb-6 text-md">
                    Reach out to us for Business Assist and receive expert guidance from small-business specialists.
                </p>
                <button className="bg-orange-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300">
                    Download the White Paper
                </button>
            </div>
            <div className="w-full md:w-1/2">
                <img
                    src={connectWithUsImage}
                    alt="A group of young women with a race car"
                    className="h-100 object-cover"
                />
            </div>
        </div>
    );
};

export default ConnectWithUs;
